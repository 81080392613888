import React from "react";
import { useTranslation } from "react-i18next";
import './privacyPolicy.scss';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
    
      <h1 style={{marginBottom:"20px" ,fontSize:"30px"}}>{t("app.overview.privacy&policy.address")}</h1>
      <h3>AirTiketa GmbH </h3>
      <h3>Address:</h3>
      <h2>Colmarer Str. 1 </h2>
      <h2 style={{marginBottom:"20px"}}>79576 Weil am Rhein, Germany</h2>
      <h3>{t("app.overview.privacy&policy.address")}</h3>
      <p>{t("app.overview.privacy&policy.responsible")}</p>
      
      <h2>{t("app.overview.privacy&policy.your_rights")}</h2>
      <p>{t("app.overview.privacy&policy.your_rights.description")}</p>
      <ul>
        <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.rights.access")}</li>
        <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.rights.rectification")}</li>
        <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.rights.deletion")}</li>
        <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.rights.restriction")}</li>
        <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.rights.objection")}</li>
        <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.rights.portability")}</li>
        <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.rights.withdrawal")}</li>
        <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.rights.complaint")}</li>
      </ul>
      <p>
        {t("app.overview.privacy&policy.rights.supervisory_link")} <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" className="text-blue-500 underline">{t("app.overview.privacy&policy.rights.supervisory_link.link")}</a>
      </p>
      
      <h2>{t("app.overview.privacy&policy.collection_info")}</h2>
      <p>{t("app.overview.privacy&policy.collection_info.description")}</p>
      <ul>
      <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.collection_info.purposes")}</li>
      <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.collection_info.legal_basis")}</li>
      <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.collection_info.recipients")}</li>
      <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.collection_info.retention")}</li>
      <li style={{fontSize:"15px"}}>{t("app.overview.privacy&policy.collection_info.necessity")}</li>
      </ul>
      
      <h2>{t("app.overview.privacy&policy.controller")}</h2>
      <p>{t("app.overview.privacy&policy.controller.details")}</p>
      
      <h2>{t("app.overview.privacy&policy.cookies")}</h2>
      <p>{t("app.overview.privacy&policy.cookies.description")}</p>
      <p>{t("app.overview.privacy&policy.cookies.function")}</p>
      <p>{t("app.overview.privacy&policy.cookies.disabling")}</p>
      
      <h2>{t("app.overview.privacy&policy.registration")}</h2>
      <p>{t("app.overview.privacy&policy.registration.description")}</p>
      <p>{t("app.overview.privacy&policy.registration.legal_basis")}</p>
      <p>{t("app.overview.privacy&policy.registration.retention")}</p>
      <p>{t("app.overview.privacy&policy.registration.necessity")}</p>
      
      <h2>{t("app.overview.privacy&policy.paid_services")}</h2>
      <p>{t("app.overview.privacy&policy.paid_services.description")}</p>
      <p>{t("app.overview.privacy&policy.paid_services.legal_basis")}</p>
      <p>{t("app.overview.privacy&policy.paid_services.retention")}</p>
      
      <h2>{t("app.overview.privacy&policy.newsletter")}</h2>
      <p>{t("app.overview.privacy&policy.newsletter.description")}</p>
      <p>{t("app.overview.privacy&policy.newsletter.legal_basis")}</p>
      <p>{t("app.overview.privacy&policy.newsletter.withdrawal")}</p>
      
      <h2>{t("app.overview.privacy&policy.contact_form")}</h2>
      <p>{t("app.overview.privacy&policy.contact_form.description")}</p>
      <p>{t("app.overview.privacy&policy.contact_form.legal_basis")}</p>
      <p>{t("app.overview.privacy&policy.contact_form.retention")}</p>
      
      <h2>{t("app.overview.privacy&policy.google_analytics")}</h2>
      <p>{t("app.overview.privacy&policy.google_analytics.description")}</p>
      <p>{t("app.overview.privacy&policy.google_analytics.legal_basis")}</p>
      
      <h2>{t("app.overview.privacy&policy.your_rights.change_privacy.description")}</h2>
      <p>{t("app.overview.privacy&policy.your_rights.change_privacy.value")}</p>
      
      <h2>{t("app.overview.privacy&policy.your_rights.data_protection.description")}</h2>
      <p>{t("app.overview.privacy&policy.your_rights.data_protection.value")}</p>
    </div>
  );
};

export default PrivacyPolicy;
