import React from "react";
import "./NotFound.scss";
import { useHistory } from "react-router";
import errorImage from "../../assets/images/error.svg";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="not-found-container">
      <div className="not-found">
        <img src={errorImage} style={{ width: "80px", height: "auto" }} />
        <h1>{t("app.notfound.title")}</h1>
        <p>{t("app.notfound.text")}</p>
        <button onClick={() => history.push("/")}>
          {t("app.notfound.button")}
        </button>
      </div>
    </div>
  );
};

export default NotFound;
