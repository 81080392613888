import React from 'react';
import { useTranslation } from "react-i18next";
import './termsAndConditions.scss';

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <div className="terms-conditions-container">
      <h1>{t('app.bookFlight.overview.termsAndConditions.checkbox.title')}</h1>
      <section>
        <h2>{t('app.bookFlight.overview.termsAndConditions.jurisdiction.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.jurisdiction')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.offer_contract.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.offer_contract')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.payment_terms.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.payment_terms')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.confidentiality.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.confidentiality')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.travel_documents.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.travel_documents')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.rebooking_cancellations.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.rebooking_cancellations')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.checkin_times.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.checkin_times')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.return_confirmation.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.return_confirmation')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.baggage.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.baggage')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.children_discounts.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.children_discounts')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.assignment.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.assignment')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.complaints.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.complaints')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.jurisdiction_place.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.jurisdiction_place')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.severability.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.severability')}</p>
        
        <h2>{t('app.bookFlight.overview.termsAndConditions.validity.title')}</h2>
        <p>{t('app.bookFlight.overview.termsAndConditions.validity')}</p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
