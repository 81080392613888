import React, {useEffect, useState} from 'react'
import styles from "./FlightRow.module.scss";
import moment from "moment";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {ReactComponent as AirplaneWhiteBackground}  from "../../assets/images/airplane-white-background.svg";
import {ReactComponent as Point} from "../../assets/images/point.svg";
import { Button } from "antd";
import Luggage  from "../../assets/images/fluent_luggage-28-filled.svg";
import Bagpack from "../../assets/images/mdi_bag-personal-outline.svg";
import Bag from "../../assets/images/mdi_bag-checked.svg";
import Ticket from "../../assets/images/icon-park-outline_ticket.svg";
import Airplane from "../../assets/images/ion_airplane.svg";

interface Props {
    flight: any;
    arrival: any;
    departure: any;
    departureDate?: any;
    className?: string;
    onClick: any;
    disabled: boolean;
    isBefore?: boolean | false;
}

const FlightRow = (props: Props) => {

    const {t} = useTranslation();
    const {flight, departure, arrival, disabled, className, onClick, departureDate} = props;
    const departureFlight = useSelector((state: AppState) => state.selectedFlights.departureFlight);

    const currencyMismatch = () => {
        if (departureFlight?.id && flight?.id !== departureFlight?.id) {
            return departureFlight?.lowestPriceWithTax.slice(-1) !== flight?.lowestPriceWithTax.slice(-1)
        }
        return false;
    }

    const formatPrice = (price: any) => {
        return price.replace('.', ',');
    };

    const formatFlightDuration = (duration :any) => {
        const [hours, minutes] = duration.split(' ');
        const hoursValue = hours.replace('h', '');
        const minutesValue = minutes ? minutes.replace('min', '') : '0';

        if (parseInt(minutesValue) === 0) {
            return `${hoursValue}h`;
        }

        return `${hoursValue}h ${minutesValue}min`;
    };

    //if we need to remove the 00 cents in fixed prices
    // const formatPrice = (price: any) => {
    //     const priceNumber = parseFloat(price);
    //
    //     if (priceNumber % 1 === 0) {
    //         return priceNumber.toFixed(0);
    //     }
    //
    //     return priceNumber.toFixed(2);
    // };
    //or
    // const formatPrice = () => {
    //     let price = flight.lowestPriceWithTax.split(".", 1);
    //     let symbol = flight.lowestPriceWithTax.split(".00 ", 2)[1];
    //     return price + " " + symbol;
    // }

    const { i18n } = useTranslation();
    const [locale, setLocale] = useState(i18n.language);

    useEffect(() => {
        moment.locale(i18n.language);
        setLocale(i18n.language);
    }, [i18n.language]);


    const basicFlightInfo = () => {
        return (
            <div className={styles.basicInfo}>
                <p>{t("app.flightRow.basicInfo.whatIsIncludedIn" , "Çfarë përfshihet në")}</p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>Airtiketa Premium</h1>
                    <h2>
                        {formatPrice(flight.lowestPriceWithTax).split(',')[0]},
                        <span>{formatPrice(flight.lowestPriceWithTax).split(',')[1]}</span>
                    </h2>
                </div>
                <div className={styles.benefits}>
                    <div><img src={Airplane}/><p>{t("app.basicFlight.baggage" , "Lejimi i bagazhit 20 kg")}</p></div>
                    <div><img src={Luggage}/><p>{t("app.basicFlight.chooseSeat" , "Zgjedhja e ulëses falas")}</p></div>
                    <div><img src={Ticket}/><p>{t("app.basicFlight.checkinBag" , "Bagazh i dorës falas (55 x 40 x 23 cm)")}</p></div>
                    <div><img src={Bag}/><p>{t("app.basicFlight.checkedBag" , "Check-in falas në aeroport")}</p></div>
                </div>
            </div>
        )
    }
    const classes = classNames(styles.flightRow, className, {[styles.disabled]: disabled || currencyMismatch() || props.isBefore});

    const midnight = moment().startOf('day');
    // Check if time of arrival is after midnight
    const isAfterMidnight = moment(flight.timeOfArrival, "hh:mm:ss").isAfter(midnight);

//If the time of arrival is after midnight, update the date
    const adjustedDepartureDate = isAfterMidnight
        ? moment(departureDate, "DD/MM/YYYY").add(1, 'day').format("dddd, D MMMM")
        : moment(departureDate, "DD/MM/YYYY").format("dddd, D MMMM");

    return (
        <>
            <div className={classes}>
                {disabled && <div
                    className={styles.sold}>{t("app.bookFlight.flights.flightRow.soldOut", "Sold out")}</div>}
                {currencyMismatch() && !disabled && <div
                    className={styles.sold}>{t("app.bookFlight.flights.flightRow.currencyMismatch", "Currency mismatch")}</div>}
                {props.isBefore && <div className={styles.sold}>{t("app.error.dateBeforeDeparture" ,"Data është para datës së nisjes")}</div>}

                <div className={styles.infoContainer}>
                    <div className={styles.flightDetails}>
                        <div className={styles.flightInfo}>
                            <div className={styles.date}>
                                {departureDate ? moment(departureDate, "DD/MM/YYYY").format("dddd, D MMMM") : ''}
                            </div>
                            <div className={styles.time}>
                                {moment(flight.timeOfDeparture, "HH:mm:ss").format("HH:mm")}
                            </div>
                            <div className={styles.airport}>{departure.name}</div>
                        </div>
                            <Point className={styles.point}/>
                            <div className={styles.horizontal}>
                                <AirplaneWhiteBackground/>
                                <p className={styles.flightTime}>
                                    {t("app.flightTime", "Koha e fluturimit: ")}
                                    {formatFlightDuration(flight.flightDuration)}
                                </p>
                            </div>
                            <Point className={styles.point}/>
                        <div className={styles.flightInfo}>
                            <div className={styles.date}>
                                { moment(flight.flightDateOfArrival, "DD/MM/YYYY").format("dddd, D MMMM") }
                            </div>
                            <div className={styles.time}>
                                {moment(flight.timeOfArrival, "HH:mm:ss").format("HH:mm")}
                            </div>
                            <div className={styles.airport}>{arrival.name}</div>
                        </div>
                    </div>

                    <div className={styles.selection}>
                        <div>
                            <h3>{t("app.selectButton.from", "nga")}</h3>
                            <h1>
                                {formatPrice(flight.lowestPriceWithTax).split(',')[0]},
                                <span>{formatPrice(flight.lowestPriceWithTax).split(',')[1]}</span>
                            </h1>
                            <p>{t("app.selectButton.perPassenger", "Per person")}</p>
                        </div>
                        <Button className={styles.select} onClick={!currencyMismatch() && onClick}>{t("app.selectButton.select" , "Zgjidh")}</Button>
                    </div>
                </div>
            </div>
            {basicFlightInfo()}
        </>
    )
}

export default FlightRow;