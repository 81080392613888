import React from 'react'
import styles from "./HomepagePassengersDropdown.module.scss";
import {ReactComponent as CloseIcon} from "../../assets/images/close.svg";
import {useTranslation} from "react-i18next";
import User from "../../assets/images/person.svg";

interface Props {
  adults: number;
  adultsDisabled: boolean;
  onAdultsIncrease: any;
  onAdultsDecrease: any;
  children: number;
  childrenDisabled: boolean;
  onChildrenIncrease: any;
  onChildrenDecrease: any;
  infants: number;
  infantsDisabled: boolean;
  onInfantsIncrease: any;
  onInfantsDecrease: any;
  onCancel: any;
  onSavePassengerButtonClick: any;
}

const HomepagePassengersDropdown = (props: Props) => {

  const {t} = useTranslation();
  const reachedMaximum = (props.adults + props.children) === 5;
  return (
      <>
        <div className="select-input-header">
        <div className="select-input-header-title">
          {t("app.home.passenger.mobile.header")}
        </div>
          <div onClick={props.onCancel}>
        <CloseIcon/>
          </div>
      </div>
        <div className={styles.homepagePassengersDropdown}>
          <h3>Passenger selection</h3>
          <div className={styles.passengerDropdown}>
            <div className={styles.title}>
              <img src={User} alt="User img"/>
              {t("app.home.form.passengers.adults.label", "Adults")}
              <span> (12+)</span>
              <br></br>
            </div>
            <div className={styles.row}>
              <button disabled={props.adultsDisabled} onClick={props.onAdultsDecrease}>-</button>
              <input type="number" value={props.adults} readOnly/>
              <button onClick={props.onAdultsIncrease} disabled={reachedMaximum}>+</button>
            </div>
          </div>
          <div className={styles.passengerDropdown}>
            <div className={styles.title}>
              <img src={User} alt="User img"/>
              {t("app.home.form.passengers.children.label", "Children")}
              <span> (2-12)</span>
              <br></br>
            </div>
            <div className={styles.row}>
              <button disabled={props.childrenDisabled} onClick={props.onChildrenDecrease}>-</button>
              <input type="number" value={props.children} readOnly/>
              <button onClick={props.onChildrenIncrease} disabled={reachedMaximum}>+</button>
            </div>
          </div>
          <div className={styles.passengerDropdown}>
            <div className={styles.title}>
              <img src={User} alt="User img"/>
              {t("app.home.form.passengers.babies.label", "Babies")}
              <span> (0-2)</span>
              <br></br>
            </div>
            <div className={styles.row}>
              <button disabled={props.infantsDisabled} onClick={props.onInfantsDecrease}><span>-</span></button>
              <input type="number" value={props.infants} readOnly/>
              <button onClick={props.onInfantsIncrease} disabled={props.adults <= props.infants}>+</button>
            </div>
          </div>
          <p>{t("app.passengerInfo", "Foshnja duhet të jetë të paktën 7 ditë dhe më pak se 2 vjeç nga dita e fluturimit."
            )}</p>
            <p>
          {t("app.home.form.passengerInfo", "Maksimumi i të rriturve dhe fëmijëve për një rezervim është 5. Për çdo të rritur mund të zgjidhni një foshnje.")}
        </p>
        </div>
        <div className="range-picker-panel-footer">
          <button className="home-page-popup-button home-page-popup-secondary-button mr-4"
                  onClick={props.onCancel}
              >
            {t("app.home.form.rangePicker.cancel.button", "Cancel") }
          </button>
          <button className="home-page-popup-button home-page-popup-primary-button"
                  onClick={props.onSavePassengerButtonClick}
              >
            {t("app.home.form.rangePicker.save.button", "Save") }
          </button>
        </div>
      </>
  )
}

export default HomepagePassengersDropdown;